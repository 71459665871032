import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
    obtenerPuntosVentas() {
      var url = ConfigAPI.baseURL + "sales-points-sales" + Session.getToken();
      return instance.get(url);
    },  
    mostrarPuntosVentas(id) {
      var url = ConfigAPI.baseURL + "sales-points-sales/" + id + Session.getToken();
      return instance.get(url);
    },    
    agregarPuntosVentas(data) {    
        var params = data
        var url = ConfigAPI.baseURL + "sales-points-sales" + Session.getToken();
        return instance.post(url,params);
    },
    editarPuntosVentas(data) {    
        var params = data    
        var url = ConfigAPI.baseURL + "sales-points-sales/"+ data.id + Session.getToken();
        return instance.post(url,params);
    },
    eliminarPuntosVentas(id) {
      var url = ConfigAPI.baseURL + "sales-points-sales/"+ id + Session.getToken();
      return instance.delete(url);
    },
    createCSR(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales-points-sales/" + data.id + "/createCSR" + Session.getToken();
      return instance.post(url,params);
    },
    connectAFIP(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales-points-sales/" + data.id + "/connectAFIP" + Session.getToken();
      return instance.post(url,params);
    },
    getLastVoucherAFIP(data) {    
      var params = data    
      var url = ConfigAPI.baseURL + "sales-points-sales/" + data.point_sale_id + "/getLastVoucherAFIP" + Session.getToken();
      return instance.post(url,params);
    },    

    obtenerMetodosPagos() {
      var url = ConfigAPI.baseURL + "sales-methods-payment" + Session.getToken();
      return instance.get(url);
    },
    mostrarMetodosPagos(id) {
      var url = ConfigAPI.baseURL + "sales-methods-payment/" + id + Session.getToken();
      return instance.get(url);
    },
    agregarMetodosPagos(data) {    
        var params = data
        var url = ConfigAPI.baseURL + "sales-methods-payment" + Session.getToken();
        return instance.post(url,params);
    },
    editarMetodosPagos(data) {    
        var params = data    
        var url = ConfigAPI.baseURL + "sales-methods-payment/"+ data.id + Session.getToken();
        return instance.post(url,params);
    },
    eliminarMetodosPagos(id) {
      var url = ConfigAPI.baseURL + "sales-methods-payment/"+ id + Session.getToken();
      return instance.delete(url);
    },

    obtenerMetodosAcopio() {
      var url = ConfigAPI.baseURL + "sales-methods-acopio" + Session.getToken();
      return instance.get(url);
    },
    mostrarMetodosAcopio(id) {
      var url = ConfigAPI.baseURL + "sales-methods-acopio/" + id + Session.getToken();
      return instance.get(url);
    },
    agregarMetodosAcopio(data) {    
        var params = data
        var url = ConfigAPI.baseURL + "sales-methods-acopio" + Session.getToken();
        return instance.post(url,params);
    },
    editarMetodosAcopio(data) {    
        var params = data    
        var url = ConfigAPI.baseURL + "sales-methods-acopio/"+ data.id + Session.getToken();
        return instance.post(url,params);
    },
    eliminarMetodosAcopio(id) {
      var url = ConfigAPI.baseURL + "sales-methods-acopio/"+ id + Session.getToken();
      return instance.delete(url);
    },    

    obtenerComprobante() {
      var url = ConfigAPI.baseURL + "sales" + Session.getToken();
      return instance.get(url);
    },
    mostrarComprobante(id) {
      var url = ConfigAPI.baseURL + "sales/" + id + Session.getToken();
      return instance.get(url);
    },
    agregarComprobante(data) {    
      var params = data
      var url = ConfigAPI.baseURL + "sales" + Session.getToken();
      return instance.post(url,params);
    },
    editarComprobante(data) {    
      var params = data    
      var url = ConfigAPI.baseURL + "sales/"+ data.id + Session.getToken();
      return instance.post(url,params);
    },
    eliminarComprobante(id) {
      var url = ConfigAPI.baseURL + "sales/"+ id + Session.getToken();
      return instance.delete(url);
    },
    getCAEComprobante(data) {
      var params = data
      var url = ConfigAPI.baseURL + "sales/afip/getCAEVoucher" + Session.getToken();
      return instance.post(url,params);      
    },    
    
    filtrarComprobante(data, page) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales/filter/query" + Session.getToken() + "&page=" + page;
      return instance.post(url,params);
    }, 
    filtrarComprobanteTrashed(data, page) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales/filter/query-trashed" + Session.getToken() + "&page=" + page;
      return instance.post(url,params);
    }, 

    aprobarComprobanteTrashed(data) {
      var params = data
      var url = ConfigAPI.baseURL + "sales/trashed/approval" + Session.getToken();
      return instance.post(url,params);    
    },
    desaprobarComprobanteTrashed(data) {
      var params = data
      var url = ConfigAPI.baseURL + "sales/trashed/disapprove" + Session.getToken();
      return instance.post(url,params);    
    },     


    filtrarComprobanteById(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales/filter/queryById" + Session.getToken();
      return instance.post(url,params);
    },  
    filtrarRecibosById(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "receipts-erp/filter/queryById" + Session.getToken();
      return instance.post(url,params);
    },        
     
    obtenerVoucherById(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales-base/get-voucher/id" + Session.getToken();
      return instance.post(url,params);
    },
    obtenerVoucherByCustomer(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales-base/get-voucher/customer" + Session.getToken();
      return instance.post(url,params);
    },  
    obtenerVoucherTotalsTemporary(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales-base/get-voucher/totals-temporary" + Session.getToken();
      return instance.post(url,params);
    },   

    obtenerClientes() {
      var url = ConfigAPI.baseURL + "customers" + Session.getToken();
      return instance.get(url);
    },
    obtenerVendedores() {
      var url = ConfigAPI.baseURL + "sellers" + Session.getToken();
      return instance.get(url);
    },   
    
    filtrarCurrentsAccountsBalance(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales-currents-accounts/filter/query/total" + Session.getToken();
      return instance.post(url,params);
    },       
    filtrarCurrentsAccounts(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales-currents-accounts/filter/query" + Session.getToken();
      return instance.post(url,params);
    },      
    filtrarCurrentsAccountsPending(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales-currents-accounts/filter/query/pending" + Session.getToken();
      return instance.post(url,params);
    }, 
    filtrarCurrentsAccountsInvoice(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales-currents-accounts/filter/query/invoice" + Session.getToken();
      return instance.post(url,params);
    },          
    editarNumeracion(data) {    
      var params = data    
      var url = ConfigAPI.baseURL + "sales-types-vouchers-points-sales-numeration/edit-number" + Session.getToken();
      return instance.post(url,params);
    },

    obtenerInvoiceByCustomer(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "sales/filter/voucherByCustomer" + Session.getToken();
      return instance.post(url,params);      
    },
    filtrarPendienteImputar(data) {
      var params = data    
      var url = ConfigAPI.baseURL + "receipts-erp/filter/pendingImpute" + Session.getToken();
      return instance.post(url,params);
    },    
    
    obtenerCondicionesIva() {
      var url = ConfigAPI.baseURL + "sales-iva-conditions" + Session.getToken();
      return instance.get(url);
    },
    mostrarCondicionIva(id) {
      var url = ConfigAPI.baseURL + "sales-iva-conditions/" + id + Session.getToken();
      return instance.get(url);
    },
    agregarCondicionIva(data) {    
        var params = data
        var url = ConfigAPI.baseURL + "sales-iva-conditions" + Session.getToken();
        return instance.post(url,params);
    },
    editarCondicionIva(data) {    
        var params = data    
        var url = ConfigAPI.baseURL + "sales-iva-conditions/"+ data.id + Session.getToken();
        return instance.post(url,params);
    },
    eliminarCondicionIva(id) {
      var url = ConfigAPI.baseURL + "sales-iva-conditions/"+ id + Session.getToken();
      return instance.delete(url);
    },

    obtenerPercepciones() {
      var url = ConfigAPI.baseURL + "sales-perceptions" + Session.getToken();
      return instance.get(url);
    },
    mostrarPercepcion(id) {
      var url = ConfigAPI.baseURL + "sales-perceptions/" + id + Session.getToken();
      return instance.get(url);
    },
    agregarPercepcion(data) {    
        var params = data
        var url = ConfigAPI.baseURL + "sales-perceptions" + Session.getToken();
        return instance.post(url,params);
    },
    editarPercepcion(data) {    
        var params = data    
        var url = ConfigAPI.baseURL + "sales-perceptions/"+ data.id + Session.getToken();
        return instance.post(url,params);
    },
    eliminarPercepcion(id) {
      var url = ConfigAPI.baseURL + "sales-perceptions/"+ id + Session.getToken();
      return instance.delete(url);
    },

    obtenerConceptos() {
      var url = ConfigAPI.baseURL + "sales-concepts" + Session.getToken();
      return instance.get(url);
    },
    mostrarConcepto(id) {
      var url = ConfigAPI.baseURL + "sales-concepts/" + id + Session.getToken();
      return instance.get(url);
    },
    agregarConcepto(data) {    
        var params = data
        var url = ConfigAPI.baseURL + "sales-concepts" + Session.getToken();
        return instance.post(url,params);
    },
    editarConcepto(data) {    
        var params = data    
        var url = ConfigAPI.baseURL + "sales-concepts/"+ data.id + Session.getToken();
        return instance.post(url,params);
    },
    eliminarConcepto(id) {
      var url = ConfigAPI.baseURL + "sales-concepts/"+ id + Session.getToken();
      return instance.delete(url);
    },

    mostrarRemito(id) {
      var url = ConfigAPI.baseURL + "remits/" + id + Session.getToken();
      return instance.get(url);
    },    

    mostrarPedido(id) {
      var url = ConfigAPI.baseURL + "orders/" + id + Session.getToken();
      return instance.get(url);
    },        

    mostrarPresupuesto(id) {
      var url = ConfigAPI.baseURL + "budgets/" + id + Session.getToken();
      return instance.get(url);      
    }
}

export default servicesAPI;
